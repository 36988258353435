export default defineNuxtRouteMiddleware((to, from) => {
  return navigateTo('/');

  // const nuxtApp = useNuxtApp();
  // const authStore = await useAuthStore(nuxtApp.$pinia);
  // const {
  //   isUserLogged,
  // } = storeToRefs(authStore);
  // const {
  //   init,
  //   getUser,
  // } = authStore;
  // const sportsBettingStore = useSportsBettingStore();
  // const { sportsBettingProviders, } = storeToRefs(sportsBettingStore);
  // const { fetchSportsBettingProviders, } = sportsBettingStore;

  // // TODO: Rip nuxt out so we don't have to do this crap.
  // // isUserLogged is always false at this point because the store is not yet initialised / data not fetched / set.
  // await init();
  // await getUser();
  // await fetchSportsBettingProviders();

  // if (
  //   !isUserLogged.value
  //   || !sportsBettingProviders.value[0].enabled
  // ) {
  //   return navigateTo('/');
  // }
});
